<template>
  <div class="login page-wrapper">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: 'LoginTemplate',
    components: {
    },
    data () {
      return {

      }
    }
  }
</script>

<style scoped>

</style>
